import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import Image from 'next/image'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import BeerCanChickenSection1Mobile from './BeerCanChickenSection1Mobile'
import DateCountdown from '@/src/components/DateCountdown'
import Link from 'next/link'

export default function BeerCanChickenCheckoutHeader({ settings }) {
  const soldOut = settings?.soldOut
  const comingSoon = settings?.comingSoon
  const comingSoonEndDate = settings?.comingSoonEndDate
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <Box
      display="flex"
      flexDirection={isMd ? 'row' : 'row'}
      paddingTop={2}
      sx={{
        backgroundColor: '#013C75',
        backgroundSize: 'fit',
        backgroundRepeat: 'repeat-x',
        backgroundImage:
          'url("https://res.cloudinary.com/dvcipe8td/image/upload/v1684088307/Beer_Can_Chicken_hero_bg_8f228077f3.png")',
      }}
    >
      <Image

        width="400px"
        height="200px"
        objectFit="contain"
        src="https://res.cloudinary.com/dvcipe8td/image/upload/v1684091968/fire_e5246e3cab.png"
      />
      <Image
        width="1000px"
        height="200px"
        objectFit="contain"
        src="https://res.cloudinary.com/dvcipe8td/image/upload/v1684091890/checkout_hero_text_b8c25ba31b.png"
      />
      <Image
        width="400px"
        height="200px"
        objectFit="contain"
        src="https://res.cloudinary.com/dvcipe8td/image/upload/v1684091981/fire_2_a4ae267e20.png"
      />
    </Box>
  )
}
