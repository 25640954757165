
export const getPackageNameForQuantity = (product, quantity) => {
  let label
  if (product?.package_type) {
    label =
      quantity === 1
        ? product.package_type.singular
        : product.package_type.plural
  } else if (product?.type === 'Gift Pack') {
    label = quantity === 1 ? 'Pack' : 'Packs'
  } else {
    label = quantity === 1 ? 'Bottle' : 'Bottles'
  }
  return label
}