import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Button, Typography } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import NarrowContainer from '@/src/common/NarrowContainer'

export default function BeerCanChickenSection4({ settings }) {
  const soldOut = settings?.soldOut
  const confirmation = settings?.confirmation
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  let copy
  if (soldOut) {
    copy = `We're sold out of PERDUE® Beer Can Chicken Beer,
    but you can still enjoy the ultimate way to enjoy
    chicken on the barbecue this summer with beer can
    chicken! It’s juicy, it’s flavorful, and it gives you an
    excuse to buy a six-pack. So, grab a PERDUE® Whole
    Chicken and let’s get that grill going! Click the link
    below for beer can chicken recipes and to buy
    PERDUE® chicken.`
  } else if (confirmation) {
    copy = `Now it's time to grab a PERDUE® Whole Chicken
    and get that grill going! Click the link below for beer
    can chicken recipes and to buy PERDUE® chicken.`
  } else {
    copy = `Beer can chicken is the ultimate way to enjoy chicken on the barbecue
    this summer! It’s juicy, it’s flavorful, and it gives you an excuse to
    buy a six-pack. So, grab a PERDUE® Whole Chicken and let’s get that
    grill going! Click the link below for beer can chicken recipes and to
    buy PERDUE® chicken.`
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={3}
      sx={{
        // flexGrow: 2,
        backgroundColor: '#00A6E6',
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        backgroundImage: isMd
          ? 'url("https://res.cloudinary.com/dvcipe8td/image/upload/v1684263244/burst_with_chicken_07c24fff25.jpg")'
          : 'url("https://res.cloudinary.com/dvcipe8td/image/upload/v1684082176/chicken_bg_62194af73e.png")',
      }}
    >
      <NarrowContainer>
        <Box display="flex" flexDirection="row" gap={1}>
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
            paddingTop="100px"
            paddingLeft={isMd ? 8 : 3}
            paddingRight={isMd ? 0 : 3}
            sx={{
              maxWidth: isMd ? '600px' : undefined,
              textAlign: 'center',
            }}
          >
            <Image
              width="300px"
              height="150px"
              objectFit="contain"
              src="https://res.cloudinary.com/dvcipe8td/image/upload/v1683469398/BCCB_Section4_title_ba4aa408f2.png"
            />
            <Typography
              color="#050b11"
              sx={{ fontFamily: 'Helvetica', fontSize: isMd ? '30px' : '20px' }}
            >
              {copy}
            </Typography>
            <Link href="https://www.perdue.com/beercanchicken">
              <a>
                <Button variant="contained">
                  GET RECIPES + BUY PERDUE CHICKEN
                </Button>
              </a>
            </Link>
            {!isMd && (
              <Box sx={{ marginBottom: -10 }}>
                <Image
                  width="200px"
                  height="200px"
                  objectFit="contain"
                  src="https://res.cloudinary.com/dvcipe8td/image/upload/v1684082177/chicken_ce3928744e.png"
                />
              </Box>
            )}
            <Image
              width="200px"
              height="200px"
              objectFit="contain"
              src="https://res.cloudinary.com/dvcipe8td/image/upload/v1683460744/BCCB_Section4_grill_f7b5fedaae.png"
              sx={{ flexGrow: 2 }}
            />
          </Box>
        </Box>
      </NarrowContainer>
    </Box>
  )
}
