import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import Image from 'next/image'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import DateCountdown from '@/src/components/DateCountdown'
import Link from 'next/link'

export default function BeerCanChickenSection1Mobile({
  settings,
  bodyCopy,
  subheadCopy,
}) {
  const soldOut = settings?.soldOut
  const comingSoon = settings?.comingSoon
  const comingSoonEndDate = settings?.comingSoonEndDate
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })
  const showBuyBtn = !soldOut && !comingSoon

  return (
    <Box
      display="flex"
      flexDirection={'column'}
      padding={2}
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: '#013C75',
        backgroundSize: 'fit',
        backgroundRepeat: 'repeat-x',
        backgroundImage:
          'url("https://res.cloudinary.com/dvcipe8td/image/upload/v1684088307/Beer_Can_Chicken_hero_bg_8f228077f3.png")',
      }}
    >
      <Image
        width="550px"
        height="444px"
        objectFit="contain"
        src="https://res.cloudinary.com/dvcipe8td/image/upload/v1684081811/hero_text_transparent_6961f43afd.png"
      />
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 2,
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        {showBuyBtn && (
          <Link href="/checkout/beercanchicken" sx={{width: '200px'}}>
            <a>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  width: '100%',
                  color: '#013C75',
                  backgroundColor: '#00A6E6',
                }}
              >
                BUY
              </Button>
            </a>
          </Link>
        )}

        <Typography
          variant="h5"
          color="#00A6E6"
          sx={{ marginTop: 1, marginBottom: '-5px' }}
        >
          {subheadCopy}
        </Typography>
        {comingSoonEndDate && (
          <Box marginRight={6} width="100%">
            <DateCountdown endDate={comingSoonEndDate} />
          </Box>
        )}
        <Typography variant="caption" color="gray">
          {bodyCopy}
        </Typography>
      </Box>
      <Image
        width="298px"
        height={isMd ? '500px' : '300px'}
        objectFit="contain"
        sx={{ marginTop: -2 }}
        src="https://res.cloudinary.com/dvcipe8td/image/upload/v1684082470/can_a926db2dcd.png"
      />
    </Box>
  )
}
