export const MAX_NUM_BOTTLES_PER_ORDER = 25;
import { DELIEVERY_TYPE_PICK_UP } from '@/consts/productConsts';
import PartnersSupportClient from '@/utils/partnersSupportClient';
import { getPackageNameForQuantity } from './productUtils';

export function priceInCentsToStrWithSymbol(priceInCents) {
  return priceInCentsToStr(priceInCents, {useSymbol: true});
}

export function applyDiscountOnPrice(price, discountPercentage) {
  return price - Math.round((discountPercentage * price) / 100);
}

export function priceInCentsToStr(priceInCents, {useSymbol, useUSD }) {
  if (!priceInCents && priceInCents !== 0) {
    return null;
  }
  const priceUsd = (priceInCents / 100).toFixed(2).replace(/[.,]00$/, '');
  if (useSymbol) {
    return `$${priceUsd}`;
  }
  if (useUSD) {
    return `${priceUsd} USD`;
  }
  return priceUsd;
}

export function getPriceSavingPerBottle(product, minPriceInCents) {
  const minPrice = minPriceInCents || getMinPricePerBottle(product);
  let maxPrice, saving;
  if (product.purchaseInfo?.retail_price_cents) {
    maxPrice = product.purchaseInfo.retail_price_cents;
  }
  else if (product.bestPriceInMarket) {
    maxPrice = product.bestPriceInMarket;
  } else {
    maxPrice = minPrice;
  }
  maxPrice = maxPrice && parseInt(maxPrice);
  saving = maxPrice - minPrice;
  return {
    maxPrice, saving
  };
}

export function getMinPricePerBottle(product) {
  const purchaseInfo = product?.purchaseInfo;
  if (!purchaseInfo) {
    return null;
  }
  let pricePerBottleInCents = purchaseInfo.price_cents;
  const quantityOverride = purchaseInfo.quantityOverride;
  if (quantityOverride) {
    quantityOverride.forEach((q) => {
      if (q.pricePerBottleInCents && q.pricePerBottleInCents < pricePerBottleInCents) {
        pricePerBottleInCents = q.pricePerBottleInCents;
      }
    });
  }
  return pricePerBottleInCents;
}

export function isShippingIncludedFromXBottles(product) {
  const shippingPriceInCentsByBottles = product.shippingPriceInCentsByBottles || {};
  let shippingIncludedFrom;
  for (let numberOfBottles = 1; numberOfBottles < MAX_NUM_BOTTLES_PER_ORDER; numberOfBottles++) {
    if (shippingPriceInCentsByBottles[numberOfBottles] === 1) {
      shippingIncludedFrom = numberOfBottles;
      break;
    }
  }
  return shippingIncludedFrom;
}


export function computeShippingCost({
  seller,
  priceElements,
  userDetails,
}) {
  if (userDetails?.delieveryType === DELIEVERY_TYPE_PICK_UP) {
    return 0
  }
  const shipmentLogic = seller.shipment_logic || [];
  const prices = shipmentLogic.map(logic => {
    if (logic.__component === 'shipment.free-above-num-of-units') {
      if (priceElements.totalAmount >=logic.minUnits) {
        return 0;
      }
    } else if (logic.__component === 'shipment.free-above-price-in-cents') {
      if (priceElements.productsTotalInCents >= logic.minPriceInCents) {
        return 0
      }
    } else if (logic.__component === 'shipment.rate-per-unit') {
      const base = logic.price_cents_first_unit || 0;
      const extra =
        logic.price_cents_each_extra_unit *
        Math.max(priceElements.totalAmount - 1, 0)
      return base + extra
    } else if (logic.__component === 'shipment.flat-rate-shipment') {
      return logic.flat_rate_in_cents;
    }
  }).filter(p => Number.isInteger(p))
  const shipmentPrice = Math.min(...prices)
  return shipmentPrice;
}

export function generateShippingCostText({
  product,
  seller,
}) {
  const shipmentLogic = seller.shipment_logic || [];
  const text = shipmentLogic.map(logic => {
    if (logic.__component === 'shipment.free-above-num-of-units') {
      return `FREE Shipping on ${logic.minUnits} ${getPackageNameForQuantity(product, logic.minUnits)} or more`
    } else if (logic.__component === 'shipment.free-above-price-in-cents') {
      return `FREE Shipping on ${priceInCentsToStrWithSymbol(logic.minPriceInCents)} or more`
    } else if (logic.__component === 'shipment.rate-per-unit') {
      const base = logic.price_cents_first_unit || 0;
      const extra = logic.price_cents_each_extra_unit
      if (base === extra) {
        return `${priceInCentsToStrWithSymbol(base)} Shipping`
      }
      return base + extra
    } else if (logic.__component === 'shipment.flat-rate-shipment') {
      return `${priceInCentsToStrWithSymbol(logic.flat_rate_in_cents)} Shipping`
    }
  })
  return text.join('\n');
}

export function computePriceBasedOnQuantity(product, numberOfBottles) {
  const numberOfBottlesInt = parseInt(numberOfBottles);
  const purchaseInfo = product?.purchaseInfo;
  if (!purchaseInfo) {
    throw `problem with product (${product?.key}) definition`;
  }

  const shippingPriceInCentsByBottles = product.shippingPriceInCentsByBottles || {};
  const shipmentPriceInCents = shippingPriceInCentsByBottles[numberOfBottles] || shippingPriceInCentsByBottles.default;

  let pricePerBottleInCents = purchaseInfo.price_cents;
  const quantityOverride = purchaseInfo.quantityOverride;
  // we assume that quantityOverride is sort by numBottles
  if (quantityOverride) {
    let loc = 0;
    while (loc < quantityOverride.length && quantityOverride[loc].numBottles <= numberOfBottlesInt) {
      if (quantityOverride[loc].numBottles === numberOfBottlesInt || quantityOverride[loc].applyAlsoOnGreaterQuantity) {
        if (quantityOverride[loc].pricePerBottleInCents) {
          pricePerBottleInCents = quantityOverride[loc].pricePerBottleInCents;
        }
      }
      loc ++;
    }
  }

  return {
    pricePerBottleInCents,
    shipmentPriceInCents
  };
}
