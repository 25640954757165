import React, {useEffect, createRef} from 'react';


import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import CircularProgress from '@mui/material/CircularProgress';
import animationData from './98288-loading';

function Loading({ animation = true, height = '300px', color}) {
  let animationContainer = createRef();
  useEffect(() => {
    const anim =
    animation &&
      lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData,
      });
    return () => anim && anim.destroy(); // optional clean up for unmounting
  }, []);
  return animation ? (
    <div className="animation-container" style={{ height }} ref={animationContainer} />
  ) : (
    <CircularProgress sx={{color}} />
  );
}

Loading.propTypes = {
  animation: PropTypes.bool,
  height: PropTypes.string,
  color: PropTypes.string
};
export default Loading;
