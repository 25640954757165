import Head from 'next/head'
import PartnersSupportClient from '@/utils/partnersSupportClient';

const Meta = ({ site, title, url, keywords, description, previewImage, noBots }) => {
  return (
    <Head>
      {(noBots) && (
        <meta name="robots" content="noindex,nofollow" />
      )}

      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="utf-8" />

      <link rel="icon" href={PartnersSupportClient.getFavIcon()} />

      <meta property="og:url" content={url || "https://sipjoy.shop"} />

      <meta property="og:image" content={previewImage || site?.metaInfo?.previewImage} />
      <meta name="keywords" content={keywords || site?.metaInfo?.keywords} />
      <meta name="description" content={description || site?.metaInfo?.description} />
      <title>{title || site?.metaInfo?.title}</title>
    </Head>
  )
}

export default Meta