import "@uiw/react-md-editor/markdown-editor.css";
import "@uiw/react-markdown-preview/markdown.css";
import dynamic from "next/dynamic";
import { useSite } from '@/src/contexts/SiteContext';

const EditerMarkdown = dynamic(
  () =>
    import("@uiw/react-md-editor").then((mod) => {
      return mod.default.Markdown;
    }),
  { ssr: false }
);

const MarkdownView = ({ fontFamily, color, children}) => {
  const { site } = useSite()
  const themeFontFamily = site?.theme?.typography?.fontFamily
  return (
    <>
      <EditerMarkdown
        source={children}
        style={{
          fontFamily: fontFamily || themeFontFamily,
          color,
        }}
      />
    </>
  )
}

export default MarkdownView