import React ,{useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import PropTypes from 'prop-types';

const DateCountdown = ({endDate}) => {
  const theme = useTheme();
  const [remaining, setRemaining] = useState();


  const numberComp = (number, text) => {
    return (
      <Box display="flex" flexDirection={'row'}  alignItems={'baseline'}>
        <Typography color="primary" sx={{ fontWeight: 600 }}>
          {number}
        </Typography>
        <Typography variant="caption" sx={{marginLeft:'2px'}}>
          {text}
        </Typography>
      </Box>
    );
  };

  const getRemainingTime = () => {
    const end = new Date(endDate);
    const now = new Date();
    const distance = end - now;
    if (distance <= 0) {
      return null;
    }
    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const _day = _hour * 24;

    return {
      days: Math.floor(distance / _day),
      hours: Math.floor((distance % _day) / _hour),
      minutes: Math.floor((distance % _hour) / _minute),
      seconds: Math.floor((distance % _minute) / _second)
    };
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      const remaining = getRemainingTime();
      setRemaining(remaining);
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  if (!remaining) {
    return null;
  }
  return (
    <Box
      padding={1}
      width={'100%'}
      component={Card}
      borderRadius={2}
      borderColor={theme.palette.primary.main}
    >
      <Box display="flex" flexDirection={'row'} justifyContent={'space-around'}>
        {numberComp(remaining?.days, 'Days')}
        {numberComp(remaining?.hours, 'Hours')}
        {numberComp(remaining?.minutes, 'Minutes')}
        {numberComp(remaining?.seconds, 'Seconds')}
      </Box>
    </Box>
  );
};


DateCountdown.propTypes = {
  endDate: PropTypes.string,
};
export default DateCountdown;
